import React from 'react';
import { Card_lists } from './styles';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from "@fortawesome/free-solid-svg-icons";

import Image_loading from '../image_loading';
 
export default function Card_list(props: any) {

   let publication = props.publication;
   let type= '';
   let history = useHistory();

   if( publication.type === 'purchase'){ 
      type = 'compra';
   }else if( publication.type === 'loan'){ 
      type = 'empréstimo';
   }
   return (
      <Card_lists>
         <Card className="mt-2 mb-2" key={`${type}-${publication.id}`} body >
            <Row>
               <Col sm={6} xs={6} md={6}>
                  <Image_loading image={publication.cover} className="card-img-1" width={140} height={170} alt={`Capa do ${publication.title} de ${publication.author.name} `} />
               </Col>
               
               <Col sm={6} xs={6} md={6}>
                  <h3>{publication.title}</h3>
                  <h4>{publication.author.name}</h4>
                  <div>
                  <Badge className="bg-secondary" key={`${type}-${publication.id}`}>{`para ${type}`}</Badge>
                  <Badge className="bg-secondary ml-2" >{(publication.enabled) ? 'Ativo': 'Desativado'}</Badge>

                  </div>
                  <div className="text-right pt-4 mt-2">
                     <Button variant="secondary" size="sm" onClick={() => history.push(`/publication/${publication.id}`)} type="button"><FontAwesomeIcon icon={faPen} className="icon_fab" /></Button>

                  </div>
               </Col>
            </Row>
         </Card>
      </Card_lists>
   )
}